import {useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";
import LayoutTemplate from "../../components/templates/layout/left";
import {loadTrashReportsRequestAction} from "../../redux/report/reducer";
import {TrashTemplate} from "../../components/templates/trash";

export const Trash = () => {
    const dispatch = useDispatch();
    const onLoad = useCallback(() => {
        dispatch(loadTrashReportsRequestAction());
    }, []);

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <LayoutTemplate>
            <TrashTemplate />
        </LayoutTemplate>
    );
};
