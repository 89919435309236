import {unstable_HistoryRouter as HistoryRouter, Routes, Route} from "react-router-dom";
import Login from "../login";
import history from "../../utils/history";
import {useDispatch} from "react-redux";
import "../../assets/css/common.css";
import {useCallback, useEffect} from "react";
import {loginCheckRequestAction} from "../../redux/user/reducer";
import User from "../user";
import AddUser from "../user/add";
import EditUser from "../user/edit";
import {Report} from "../report";
import {Trash} from "../trash";

const App = () => {
    const dispatch = useDispatch();
    const loginCheck = useCallback(() => {
        dispatch(loginCheckRequestAction());
    }, []);

    history.listen(() => {
        loginCheck();
    });

    useEffect(() => {
        loginCheck();
    }, []);

    return (
        <HistoryRouter history={history}>
            <Routes>
                <Route path="/login" element={<Login />}/>

                <Route path="/user" element={<User />}/>
                <Route path="/user/add" element={<AddUser />}/>
                <Route path="/user/edit/:seq" element={<EditUser />}/>

                <Route path="/report" element={<Report />}/>

                <Route path="/trash" element={<Trash />}/>
            </Routes>
        </HistoryRouter>
    );
}

export default App;
