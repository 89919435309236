export const LOAD_REPORTS_REQUEST = 'LOAD_REPORTS_REQUEST';
export const LOAD_REPORTS_SUCCESS = 'LOAD_REPORTS_SUCCESS';
export const LOAD_REPORTS_FAILURE = 'LOAD_REPORTS_FAILURE';

export const LOAD_TRASH_REPORTS_REQUEST = 'LOAD_TRASH_REPORTS_REQUEST';
export const LOAD_TRASH_REPORTS_SUCCESS = 'LOAD_TRASH_REPORTS_SUCCESS';
export const LOAD_TRASH_REPORTS_FAILURE = 'LOAD_TRASH_REPORTS_FAILURE';

export const LOAD_REPORT_REQUEST = 'LOAD_REPORT_REQUEST';
export const LOAD_REPORT_SUCCESS = 'LOAD_REPORT_SUCCESS';
export const LOAD_REPORT_FAILURE = 'LOAD_REPORT_FAILURE';

export const INSERT_REPORT_REQUEST = 'INSERT_REPORT_REQUEST';
export const INSERT_REPORT_SUCCESS = 'INSERT_REPORT_SUCCESS';
export const INSERT_REPORT_FAILURE = 'INSERT_REPORT_FAILURE';

export const UPDATE_REPORT_REQUEST = 'UPDATE_REPORT_REQUEST';
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS';
export const UPDATE_REPORT_FAILURE = 'UPDATE_REPORT_FAILURE';

export const DELETE_REPORT_REQUEST = 'DELETE_REPORT_REQUEST';
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';
export const DELETE_REPORT_FAILURE = 'DELETE_REPORT_FAILURE';

export const RESTORE_REPORT_REQUEST = 'RESTORE_REPORT_REQUEST';
export const RESTORE_REPORT_SUCCESS = 'RESTORE_REPORT_SUCCESS';
export const RESTORE_REPORT_FAILURE = 'RESTORE_REPORT_FAILURE';
