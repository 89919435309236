import {useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";
import LayoutTemplate from "../../components/templates/layout/left";
import {loadReportsRequestAction} from "../../redux/report/reducer";
import {ReportTemplate} from "../../components/templates/report";

export const Report = () => {
    const dispatch = useDispatch();
    const onLoad = useCallback(() => {
        dispatch(loadReportsRequestAction());
    }, []);

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <LayoutTemplate>
            <ReportTemplate />
        </LayoutTemplate>
    );
};
