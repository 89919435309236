import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useCallback, useMemo, useState} from "react";
import {deleteUserRequestAction} from "../../../redux/user/reducer";
import {showAlertRequestAction} from "../../../redux/alert/reducer";
import Table from "../../atoms/table";
import history from "../../../utils/history";
import Tag from "../../atoms/tag";
import LayoutRightTemplate from "../layout/right";
import Action from "../../atoms/table/action";

const UserTemplate = () => {
    const dispatch = useDispatch();
    const users = useSelector(((state) => state.user.users), shallowEqual);

    const goAdd = useCallback(() => {
        history.push('/user/add');
    }, []);

    const goEdit = useCallback((seq) => {
        history.push(`/user/edit/${seq}`);
    }, []);

    const onDelete = useCallback((seq) => {
        dispatch(showAlertRequestAction({
            showAlert: true, title: '정말 삭제하시겠습니까?', onSubmit: () => {
                dispatch(deleteUserRequestAction({seq}));
                window.location.reload();
            }
        }));
    }, []);

    const columns = useMemo(
        () => [
            {Header: '상태', accessor: 'useYn', width: '5%'},
            {Header: '번호', accessor: 'seq', width: '5%'},
            {Header: '아이디', accessor: 'id', width: '10%'},
            {Header: '이메일', accessor: 'email', width: '15%'},
            {Header: '닉네임', accessor: 'name', width: '10%'},
            {Header: '권한', accessor: 'role', width: '5%'},
            {Header: 'IP', accessor: 'lastLoginIp', width: '10%'},
            {Header: '접속일', accessor: 'lastLoginDate', width: '11%'},
            {Header: '가입일', accessor: 'insertDate', width: '11%'},
            {Header: '수정일', accessor: 'updateDate', width: '11%'},
            {Header: '', accessor: 'action', width: '5%'},
        ], []);

    const data = useMemo(() => users.length > 0 ? users.map((x) => {
        return {
            ...x,
            useYn: x.useYn === 0 ? <Tag theme={'red'}>탈퇴</Tag> : <Tag theme={'navy'}>정상</Tag>,
            action: Action(x.seq, goEdit, onDelete),
        };
    }) : '', [users]);

    if (users.length > 0) return (
        <LayoutRightTemplate title={'유저 관리'} clickTitle={'등록'} onClick={goAdd}>
            <Table columns={columns} data={data} mainSearch={true}/>
        </LayoutRightTemplate>
    );
};

export default UserTemplate;
