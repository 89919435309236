import {
    DELETE_REPORT_FAILURE,
    DELETE_REPORT_REQUEST,
    DELETE_REPORT_SUCCESS,
    INSERT_REPORT_FAILURE,
    INSERT_REPORT_REQUEST,
    INSERT_REPORT_SUCCESS,
    LOAD_REPORT_FAILURE,
    LOAD_REPORT_REQUEST,
    LOAD_REPORT_SUCCESS,
    LOAD_REPORTS_FAILURE,
    LOAD_REPORTS_REQUEST,
    LOAD_REPORTS_SUCCESS,
    LOAD_TRASH_REPORTS_FAILURE,
    LOAD_TRASH_REPORTS_REQUEST,
    LOAD_TRASH_REPORTS_SUCCESS, RESTORE_REPORT_FAILURE,
    RESTORE_REPORT_REQUEST,
    RESTORE_REPORT_SUCCESS,
    UPDATE_REPORT_FAILURE,
    UPDATE_REPORT_REQUEST,
    UPDATE_REPORT_SUCCESS
} from "./constants";
import produce from "immer";

const initialState = {
    report: {},
    reports: [],
    loadReportsLoading: false,
    loadReportsDone: false,
    loadReportsError: false,
    loadTrashReportsLoading: false,
    loadTrashReportsDone: false,
    loadTrashReportsError: false,
    loadReportLoading: false,
    loadReportDone: false,
    loadReportError: false,
    insertReportLoading: false,
    insertReportDone: false,
    insertReportError: false,
    deleteReportLoading: false,
    deleteReportDone: false,
    deleteReportError: false,
    restoreReportLoading: false,
    restoreReportDone: false,
    restoreReportError: false,
}

export const loadReportsRequestAction = (data) => {
    return {
        type: LOAD_REPORTS_REQUEST,
        data,
    }
}

export const loadTrashReportsRequestAction = (data) => {
    return {
        type: LOAD_TRASH_REPORTS_REQUEST,
        data,
    }
}

export const loadReportRequestAction = (data) => {
    return {
        type: LOAD_REPORT_REQUEST,
        data,
    }
}

export const updateReportRequestAction = (data) => {
    return {
        type: UPDATE_REPORT_REQUEST,
        data,
    }
}

export const insertReportRequestAction = (data) => {
    return {
        type: INSERT_REPORT_REQUEST,
        data
    }
}

export const deleteReportRequestAction = (data) => {
    return {
        type: DELETE_REPORT_REQUEST,
        data
    }
}

export const restoreReportRequestAction = (data) => {
    return {
        type: RESTORE_REPORT_REQUEST,
        data
    }
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case LOAD_REPORT_REQUEST:
            draft.loadReportLoading = true;
            draft.loadReportDone = false;
            draft.loadReportError = null;
            break;
        case LOAD_REPORT_SUCCESS:
            draft.loadReportLoading = false;
            draft.loadReportDone = true;
            draft.report = action.data.data;
            break;
        case LOAD_REPORT_FAILURE:
            draft.loadReportLoading = false;
            draft.loadReportDone = false;
            draft.loadReportError = action.data;
            break;
        case LOAD_REPORTS_REQUEST:
            draft.loadReportsLoading = true;
            draft.loadReportsDone = false;
            draft.loadReportsError = null;
            break;
        case LOAD_REPORTS_SUCCESS:
            draft.loadReportsLoading = false;
            draft.loadReportsDone = true;
            draft.reports = action.data.data;
            break;
        case LOAD_REPORTS_FAILURE:
            draft.loadTrashReportsLoading = false;
            draft.loadTrashReportsDone = false;
            draft.loadTrashReportsError = action.data;
            break;
        case LOAD_TRASH_REPORTS_REQUEST:
            draft.loadTrashReportsLoading = true;
            draft.loadTrashReportsDone = false;
            draft.loadTrashReportsError = null;
            break;
        case LOAD_TRASH_REPORTS_SUCCESS:
            draft.loadTrashReportsLoading = false;
            draft.loadTrashReportsDone = true;
            draft.reports = action.data.data;
            break;
        case LOAD_TRASH_REPORTS_FAILURE:
            draft.loadReportsLoading = false;
            draft.loadReportsDone = false;
            draft.loadReportsError = action.data;
            break;
        case INSERT_REPORT_REQUEST:
            draft.insertReportLoading = true;
            draft.insertReportDone = false;
            draft.insertReportError = null;
            break;
        case INSERT_REPORT_SUCCESS:
            draft.insertReportLoading = false;
            draft.insertReportDone = true;
            break;
        case INSERT_REPORT_FAILURE:
            draft.insertReportLoading = false;
            draft.insertReportDone = false;
            draft.insertReportError = action.data;
            break;
        case UPDATE_REPORT_REQUEST:
            draft.updateReportLoading = true;
            draft.updateReportDone = false;
            draft.updateReportError = null;
            break;
        case UPDATE_REPORT_SUCCESS:
            draft.updateReportLoading = false;
            draft.updateReportDone = true;
            break;
        case UPDATE_REPORT_FAILURE:
            draft.updateReportLoading = false;
            draft.updateReportDone = false;
            draft.updateReportError = action.data;
            break;
        case DELETE_REPORT_REQUEST:
            draft.deleteReportLoading = true;
            draft.deleteReportDone = false;
            draft.deleteReportError = null;
            break;
        case DELETE_REPORT_SUCCESS:
            draft.deleteReportLoading = false;
            draft.deleteReportDone = true;
            break;
        case DELETE_REPORT_FAILURE:
            draft.deleteReportLoading = false;
            draft.deleteReportDone = false;
            draft.deleteReportError = action.data;
            break;
        case RESTORE_REPORT_REQUEST:
            draft.restoreReportLoading = true;
            draft.restoreReportDone = false;
            draft.restoreReportError = null;
            break;
        case RESTORE_REPORT_SUCCESS:
            draft.restoreReportLoading = false;
            draft.restoreReportDone = true;
            break;
        case RESTORE_REPORT_FAILURE:
            draft.restoreReportLoading = false;
            draft.restoreReportDone = false;
            draft.restoreReportError = action.data;
            break;
    }
});

export default reducer;

