import axios from "axios";
import {
    DELETE_REPORT_FAILURE,
    DELETE_REPORT_REQUEST,
    DELETE_REPORT_SUCCESS,
    INSERT_REPORT_FAILURE,
    INSERT_REPORT_REQUEST,
    INSERT_REPORT_SUCCESS,
    LOAD_REPORT_FAILURE,
    LOAD_REPORT_REQUEST,
    LOAD_REPORT_SUCCESS,
    LOAD_REPORTS_FAILURE,
    LOAD_REPORTS_REQUEST,
    LOAD_REPORTS_SUCCESS,
    LOAD_TRASH_REPORTS_FAILURE,
    LOAD_TRASH_REPORTS_REQUEST,
    LOAD_TRASH_REPORTS_SUCCESS,
    RESTORE_REPORT_FAILURE, RESTORE_REPORT_REQUEST,
    RESTORE_REPORT_SUCCESS,
    UPDATE_REPORT_FAILURE,
    UPDATE_REPORT_REQUEST,
    UPDATE_REPORT_SUCCESS
} from "./constants";
import {all, fork, takeLatest, call, put, throttle} from 'redux-saga/effects';
import history from "../../utils/history";

function loadReportsAPI() {
    return axios.get('/admin/api/v1/report')
}

function loadTrashReportsAPI() {
    return axios.get('/admin/api/v1/report/trash')
}

function loadReportAPI(data) {
    return axios.get(`/admin/api/v1/report/${data.seq}`)
}

function insertReportAPI(data) {
    return axios.post('/admin/api/v1/report', data)
}

function updateReportAPI(data) {
    return axios.put('/admin/api/v1/report', data);
}

function deleteReportAPI(data) {
    return axios.delete('/admin/api/v1/report', {data})
}

function restoreReportAPI(data) {
    return axios.put('/admin/api/v1/report/restore', data);
}

function* loadReports(action) {
    try {
        const result = yield call(loadReportsAPI, action.data);
        yield put({
            type: LOAD_REPORTS_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_REPORTS_FAILURE,
            data: err.response.data,
        });
    }
}

function* loadTrashReports(action) {
    try {
        const result = yield call(loadTrashReportsAPI, action.data);
        yield put({
            type: LOAD_TRASH_REPORTS_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_TRASH_REPORTS_FAILURE,
            data: err.response.data,
        });
    }
}

function* loadReport(action) {
    try {
        const result = yield call(loadReportAPI, action.data);
        yield put({
            type: LOAD_REPORT_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_REPORT_FAILURE,
            data: err.response.data,
        });
    }
}

function* insertReport(action) {
    try {
        const result = yield call(insertReportAPI, action.data);
        yield put({
            type: INSERT_REPORT_SUCCESS,
            data: result.data,
        });
        history.push('/report');
    } catch (err) {
        yield put({
            type: INSERT_REPORT_FAILURE,
            data: err.response.data,
        });
        alert(err.response.data.data.message);
    }
}

function* updateReport(action) {
    try {
        const result = yield call(updateReportAPI, action.data);
        yield put({
            type: UPDATE_REPORT_SUCCESS,
            data: result.data,
        });
        history.push('/report');
    } catch (err) {
        yield put({
            type: UPDATE_REPORT_FAILURE,
            data: err.response.data,
        });
    }
}

function* deleteReport(action) {
    try {
        const result = yield call(deleteReportAPI, action.data);
        yield put({
            type: DELETE_REPORT_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: DELETE_REPORT_FAILURE,
            data: err.response.data,
        });
    }
}

function* restoreReport(action) {
    try {
        const result = yield call(restoreReportAPI, action.data);
        yield put({
            type: RESTORE_REPORT_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: RESTORE_REPORT_FAILURE,
            data: err.response.data,
        });
    }
}

function* watchLoadReports() {
    yield takeLatest(LOAD_REPORTS_REQUEST, loadReports);
}

function* watchLoadTrashReports() {
    yield takeLatest(LOAD_TRASH_REPORTS_REQUEST, loadTrashReports);
}

function* watchLoadReport() {
    yield takeLatest(LOAD_REPORT_REQUEST, loadReport);
}

function* watchInsertReport() {
    yield throttle(1000, INSERT_REPORT_REQUEST, insertReport);
}

function* watchUpdateReport() {
    yield throttle(1000, UPDATE_REPORT_REQUEST, updateReport);
}

function* watchDeleteReport() {
    yield throttle(1000, DELETE_REPORT_REQUEST, deleteReport);
}

function* watchRestoreReport() {
    yield throttle(1000, RESTORE_REPORT_REQUEST, restoreReport);
}

export default function* saga() {
    yield all([
        fork(watchLoadReports),
        fork(watchLoadTrashReports),
        fork(watchLoadReport),
        fork(watchInsertReport),
        fork(watchUpdateReport),
        fork(watchDeleteReport),
        fork(watchRestoreReport),
    ])
}

