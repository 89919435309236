import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useCallback, useMemo} from "react";
import Table from "../../atoms/table";
import LayoutRightTemplate from "../layout/right";
import {showAlertRequestAction} from "../../../redux/alert/reducer";
import {deleteReportRequestAction} from "../../../redux/report/reducer";
import styled from "styled-components";

export const TrashTemplate = () => {
    const dispatch = useDispatch();
    const reports = useSelector(((state) => state.report.reports), shallowEqual);

    const goView = useCallback(({name, school, grade, birth, level, choose}) => {
        window.open(`https://jennifer-academy.com/viewer?name=${name}&school=${school}&grade=${grade}&birth=${birth}&subject=${level}&chooseString=${choose}`, '_blank')
    }, []);

    const onIndicates = useCallback((subject, score) => {
        switch (subject) {
            case 'preTestA':
                if (score < 61) {
                    return 'Beginner'
                } else {
                    return 'Starter'
                }
            case 'preTestB':
                if (score < 61) {
                    return 'Basic'
                } else if (score < 91) {
                    return 'intermediate'
                } else {
                    return 'advanced'
                }
            case 'beginner':
                if (score < 51) {
                    return 'Phonics Final'
                } else if (score < 86) {
                    return 'Starter'
                } else {
                    return 'Starter+'
                }
            case 'starter':
                if (score < 61) {
                    return 'Starter'
                } else if (score < 81) {
                    return 'Starter+'
                } else {
                    return 'Basic'
                }
            case 'basic':
                if (score < 71) {
                    return 'Basic'
                } else if (score < 91) {
                    return 'Basic+'
                } else {
                    return 'Intermediate'
                }
            case 'intermediate':
                if (score < 86) {
                    return 'Intermediate';
                } else {
                    return 'Advanced';
                }
            case 'advanced':
                if (score < 91) {
                    return 'Advanced';
                } else {
                    return 'Master';
                }
        }
    }, []);

    const onRestore = useCallback((seq) => {
        dispatch(showAlertRequestAction({
            showAlert: true, title: '복구하시겠습니까?', onSubmit: () => {
                dispatch(deleteReportRequestAction({seq}));
                window.location.reload();
            }
        }));
    }, []);

    const onDelete = useCallback((seq) => {
        dispatch(showAlertRequestAction({
            showAlert: true, title: '정말 삭제하시겠습니까?', onSubmit: () => {
                dispatch(deleteReportRequestAction({seq}));
                window.location.reload();
            }
        }));
    }, []);

    const columns = useMemo(
        () => [
            {Header: '이름', accessor: 'name', width: '5%'},
            {Header: '학교', accessor: 'school', width: '5%'},
            {Header: '학년', accessor: 'grade', width: '5%'},
            {Header: '생일', accessor: 'birth', width: '5%'},
            {Header: '레벨', accessor: 'level', width: '5%'},
            {Header: '점수', accessor: 'score', width: '5%'},
            {Header: '등록일', accessor: 'insertDate', width: '5%'},
            {Header: '보기', accessor: 'action', width: '5%'},
        ], []);

    const data = useMemo(() => reports.length > 0 ? reports.map((x) => {
        return {
            ...x,
            score: `${x.score} (${onIndicates(x.level, x.score)})`,
            action:
                <ActionWrap>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="lightseagreen"
                         style={{cursor: 'pointer'}}
                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-rotate-ccw" onClick={() => onRestore(x.seq)}>
                        <path d="M3 2v6h6"></path>
                        <path d="M3 13a9 9 0 1 0 3-7.7L3 8"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="blueviolet"
                         style={{cursor: 'pointer'}}
                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-search" onClick={() => goView({...x})}>
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" x2="16.65" y1="21" y2="16.65"></line>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"
                         style={{cursor: 'pointer'}}
                         stroke="red" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" onClick={() => onDelete(x.seq)}
                         className="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"/>
                        <line x1="6" y1="6" x2="18" y2="18"/>
                    </svg>
                </ActionWrap>
        };
    }) : '', [reports]);

    if (reports.length > 0) return (
        <LayoutRightTemplate title={'리포트 관리'}>
            <Table columns={columns} data={data} mainSearch={true}/>
        </LayoutRightTemplate>
    );
};

const ActionWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;

  & svg {
    cursor: pointer;
  }
`
